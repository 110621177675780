@import './variables.scss';

body {
  margin: 0;
  background: $black;
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', Arial;
  font-size: 100%;
}

.animate,
.animated {
  $speed: 0.2s;
  -o-transition: $speed;
  -ms-transition: $speed;
  -moz-transition: $speed;
  -webkit-transition: $speed;
  transition: $speed;

  &.slow {
    $speed: 0.5s;
    -o-transition: $speed;
    -ms-transition: $speed;
    -moz-transition: $speed;
    -webkit-transition: $speed;
    transition: $speed;
  }
}
.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.clearfix {
  clear: both;
}
