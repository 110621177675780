// brand colors
$black: #0d0d0d;
$white: #ffffff;
$ivory: #f2e9d8;
$gold: #bf8c2c;
$tan: #bfb49f;
$maroon: #730d45;
$colPrimary: $gold;

// general colors
$silver: #e2e2ea;
$gray: #bfbfbf;
$grayMedium: #b5b5be;
$darkGray: #555555;

$blue: #50b5ff;
$red: #e84c5b;
$yellow: #f3bb57;
$green: #3fb05a;
$colError: $red;
$colSuccess: $green;

// sizes
$headerHeight: 80px;
$adminBarHeight: 60px;
$pageWidth: 1000px;
$tabletBreakpoint: 800px;
$mobileBreakpoint: 600px;
